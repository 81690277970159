
export default {
	START_WIDTH: 640,
	START_HEIGHT: 480,
	MODEL_SCALE: 1.0,
	MODEL_POS_Y: 1,
	MODEL_ROT_X: -Math.PI / 2,
	TIME_LOOP_IN: 2.7,
	TIME_LOOP_OUT: 6.3,
	SPLIT_ANIMS: false,
	ANIM_TIMESCALE_NORMAL: 1.0,
	ANIM_TIMESCALE_END: 3.0,
	SPEED_TO_END: false,
	TOTAL_UNCOMPRESSED: 10489856, // set by compress.sh
};
