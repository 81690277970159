
import ArDisplay from './display';
import Loader from './loader';
import config from './config';

class Controller {
	constructor() {
		this.update = this.update.bind(this);
		this.onClick = this.onClick.bind(this);

		const settings = {
			showScene: /show=1/.test(window.location.search),
		};

		this.arDisplay = new ArDisplay(settings);
		this.loader = new Loader(this);
		this.loaded = false;
	}

	load() {
		this.loadingNode = document.querySelector('.loading');
		window.requestAnimationFrame(this.update);

		Promise.all([
			this.arDisplay.init(),
			this.loader.init(),
		]).then(() => {
			this.arDisplay.setModel(this.loader.getModel());

			this.loadingNode.parentNode.removeChild(this.loadingNode);
			this.loadingNode = null;
			this.loaded = true;

			document.body.addEventListener('click', this.onClick);
		});
	}

	update(time) {
		if (this.loaded) {
			this.arDisplay.update(time);
		} else {
			const loadedPercent = this.loader.getProgress().toFixed(2);
			this.loadingNode.innerText = `LOADING... ${loadedPercent}%`;
		}

		window.requestAnimationFrame(this.update);
	}

	onClick() {
		const model = this.arDisplay.getModel();
		if (model === null) {
			return;
		}

		if (config.SPEED_TO_END) {
			model.end();
		} else {
			model.stop();
			this.arDisplay.setModel(this.loader.nextModel());
		}
	}

	onModelFinished() {
		this.arDisplay.setModel(this.loader.nextModel());
	}
};


const controller = new Controller();
window.addEventListener('load', () => controller.load());
