
import config from './config';

const STATE_PLAY = 'state-play';
const STATE_END = 'state-end';

class Model {
	constructor(controller, content, clips) {
		this.onFinished = this.onFinished.bind(this);

		this.lastTime = null;
		this.nextIndex = null;
		this.controller = controller;
		this.content = content;
		this.state = STATE_PLAY;

		this.mixer = new THREE.AnimationMixer(this.content);

		this.clipIndex = 0;
		this.clips = clips;
		this.actions = this.clips.map(clip => {
			const action = this.mixer.clipAction(clip);
			action.setLoop(THREE.LoopOnce);
			action.clampWhenFinished = true;
			return action;
		});

		this.mixer.addEventListener('finished', this.onFinished);
	}

	update(time) {
		const dt = this.lastTime === null
			? 0
			: (time - this.lastTime) / 1000.0;

		if (this.nextIndex !== null) {
			this.startClip(this.nextIndex);
			this.nextIndex = null;
		}

		this.mixer.update(dt * this.mixer.timeScale);

		this.lastTime = time;
	}

	play() {
		this.state = STATE_PLAY;
		this.mixer.timeScale = config.ANIM_TIMESCALE_NORMAL;
		this.startClip(0);
	}

	stop() {
		this.actions[this.clipIndex].stop();
	}

	startClip(index) {
		if (index === this.clips.length) {
			if (this.state === STATE_END) {
				this.controller.onModelFinished();
			}

			return;
		}

		this.clipIndex = index;
		this.lastTime = null;
		this.actions[this.clipIndex].reset().play();
	}

	end() {
		if (!this.actions[this.clipIndex].isRunning()) {
			// already ended
			this.controller.onModelFinished();
			return;
		}

		this.state = STATE_END;
		this.mixer.timeScale = config.ANIM_TIMESCALE_END;
	}

	onFinished() {
		if (this.clips.length > 1 && this.clips[this.clipIndex].name === 'loop' && this.state === STATE_PLAY) {
			this.nextIndex = this.clipIndex;
		} else {
			this.nextIndex = this.clipIndex + 1;
		}
	}
}

export default Model;
