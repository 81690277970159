
import { splitClip } from './clips';
import Model from './model';
import config from './config';

import modelPaths from './assets/*.glb';
import vertexColorsVert from './shaders/vertexColor.vert.glsl';
import vertexColorsFrag from './shaders/vertexColor.frag.glsl';

const USE_VERTEX_MATERIAL = /^$|vertex|unnamed/i;

class Loader {
	constructor(controller) {
		this.loadModel = this.loadModel.bind(this);

		this.controller = controller;

		this.loader = new THREE.GLTFLoader();

		this.vertexMaterial = new THREE.ShaderMaterial({
			vertexShader: vertexColorsVert,
			fragmentShader: vertexColorsFrag,
			vertexColors: THREE.VertexColors,
			side: THREE.DoubleSide
		});

		this.models = [];
		this.modelIndex = 0;
		this.progress = new Float32Array(Object.keys(modelPaths).length);
		this.progressUncompressed = false;
	}

	init() {
		return new Promise((resolve, reject) =>
			Promise.all(Object.entries(modelPaths).map(this.loadModel)).then((models) => {
				this.models = models;
				resolve();
			})
		);
	}

	loadModel([pathId, pathValue], index) {
		return new Promise((resolve, reject) =>
			this.loader.load(
				pathValue,
				gltf => {
					const content = gltf.scene || gltf.scenes[0];

					// use vertex colours for empty materials
					content.traverse(node => {
						if (!node.material || USE_VERTEX_MATERIAL.test(node.material.name)) {
							node.material = this.vertexMaterial;
						} else {
							node.material.side = THREE.DoubleSide;
						}
					});

					const animations = [];

					if (config.SPLIT_ANIMS) {
						// split animation into distinct clips
						const clipIntro = gltf.animations[0];
						const clipLoop = splitClip(clipIntro, config.TIME_LOOP_IN, 'loop');
						const clipOutro = splitClip(clipLoop, config.TIME_LOOP_OUT - config.TIME_LOOP_IN, 'outro');
						animations.push(clipIntro, clipLoop, clipOutro);
					} else {
						// single animation
						animations.push(gltf.animations[0]);
					}

					resolve(new Model(
						this.controller,
						content,
						animations,
					));
				},
				xhr => {
					if (xhr.lengthComputable) {
						this.progress[index] = xhr.loaded / xhr.total;
					} else {
						// Chrome gives us the uncompressed amount
						// loaded and no total, so fall back to the
						// decompressed total set in the config by
						// the build process
						this.progress[index] = xhr.loaded;
						this.progressUncompressed = true;
					}
				},
				err => {
					console.log(`Error loading GLTF: ${err}`);
					throw new Error(err);
					reject(err);
				}
			)
		);
	}

	getModel() {
		return this.models[this.modelIndex];
	}

	nextModel() {
		this.modelIndex = (this.modelIndex + 1) % this.models.length;
		return this.models[this.modelIndex];
	}

	getProgress() {
		const sum = this.progress.reduce((a, b) => a + b);
		const progress = sum / (this.progressUncompressed
			? config.TOTAL_UNCOMPRESSED
			: this.progress.length);

		return 100 * progress;
	}
};

export default Loader;
