import ArPattern from './assets/qr2.patt';
import ArCameraPara from './assets/camera_para.dat';

import config from './config';

class ArDisplay {
	constructor(settings) {
		this.hideMidgets = this.hideMidgets.bind(this);

		this.settings = settings;

		// three setup

		this.renderer = new THREE.WebGLRenderer({
			antialias: true,
			alpha: true,
		});

		this.renderer.setClearColor(new THREE.Color(0), 0)
		this.renderer.setSize(config.START_WIDTH, config.START_HEIGHT);
		document.body.appendChild(this.renderer.domElement);

		this.scene = new THREE.Scene();
		this.scene.visible = false;

		this.camera = new THREE.PerspectiveCamera(70, config.START_WIDTH / config.START_HEIGHT, 0.001, 20);
		this.scene.add(this.camera);

		this.model = null;

		// AR.js setup

		this.source = new THREEx.ArToolkitSource({
			sourceType: 'webcam',
		});

		this.context = new THREEx.ArToolkitContext({
			cameraParametersUrl: ArCameraPara,
			detectionMode: 'mono',
		});

		this.controls = new THREEx.ArMarkerControls(this.context, this.camera, {
			type: 'pattern',
			patternUrl: ArPattern,
			changeMatrixMode: 'cameraTransformMatrix'
		});
	}

	init() {
		return new Promise((resolve, reject) => {
			this.source.init(() => {
				this.onResize();
				this.context.init(() => {
					// ignore AR.js projection matrix
					//this.camera.projectionMatrix.copy(this.context.getProjectionMatrix());
					resolve();
				});
			});
		});
	}

	onResize() {
		this.source.onResizeElement();
		this.source.copyElementSizeTo(this.renderer.domElement);
		if (this.context.arController !== null) {
			this.source.copyElementSizeTo(this.context.arController.canvas);
		}
	}

	getModel() {
		return this.model;
	}

	setModel(model) {
		if (this.model !== null) {
			// remove old content
			this.scene.remove(this.model.content);
		}

		this.model = model;

		// position content in front of marker
		if (!this.settings.showScene) {
			this.model.content.scale.set(config.MODEL_SCALE, config.MODEL_SCALE, config.MODEL_SCALE);
			this.model.content.position.y = config.MODEL_POS_Y;
			this.model.content.rotation.x = config.MODEL_ROT_X;
		}

		this.scene.add(this.model.content);
		model.play();
	}

	update(time) {
		if (this.source.ready) {
			this.context.update(this.source.domElement);
			this.scene.visible = this.camera.visible;
		}

		if (this.model !== null) {
			this.model.update(time);

			if (!this.controls.object3d.visible && this.settings.showScene) {
				// test mode to show scene without AR marker
				this.scene.visible = true;
				this.camera.visible = true;
				this.model.content.position.set(0, -1, -10);
				this.model.content.rotation.y += 0.01;
			}

			// hide nodes with zero scale to suppress errors
			// N.B. scale might be animated by the mixer update
			// called before this update
			this.scene.traverse(this.hideMidgets);
		}

		this.render();
	}

	hideMidgets(node) {
		node.visible = this.camera.visible
			&& node.scale.x !== 0
			&& node.scale.y !== 0
			&& node.scale.z !== 0;
	}

	render() {
		this.renderer.render(this.scene, this.camera);
	}
}

export default ArDisplay;
