
function copyClip(clip) {
	const newClip = THREE.AnimationClip.parse(
		THREE.AnimationClip.toJSON(
			clip
		)
	);

	// copy special GLTF interpolant for cubic spline tracks
	for (const [i, track] of Object.entries(clip.tracks)) {
		if (track.createInterpolant.isInterpolantFactoryMethodGLTFCubicSpline) {
			newClip.tracks[i].createInterpolant = track.createInterpolant;
		}
	}

	return newClip;
}

/**
 * Returns a new array of the same type, with the provided
 * elements inserted at the start or end.
 */
function insertTypedElements(array, insertAtStart, elements) {
	const newArray = new array.constructor(array.length + elements.length);

	if (insertAtStart) {
		newArray.set(elements);
		newArray.set(array, elements.length);
	} else {
		newArray.set(array);
		newArray.set(elements, array.length);
	}

	return newArray;
}

/**
 * Returns a new KeyframeTrack with the provided keyframe
 * time & value.
 */
function insertKeyFrame(track, insertAtStart, time, values) {
	const interpolation = track.createInterpolant.isInterpolantFactoryMethodGLTFCubicSpline
		? THREE.InterpolateSmooth
		: track.getInterpolation();

	const newTrack = new track.constructor(
		track.name,
		insertTypedElements(track.times, insertAtStart, [time]),
		insertTypedElements(track.values, insertAtStart, values),
		interpolation,
	);

	if (track.createInterpolant.isInterpolantFactoryMethodGLTFCubicSpline) {
		newTrack.createInterpolant = track.createInterpolant;
	}

	return newTrack;
}

/**
 * Calculates the value of a KeyframeTrack at the given time
 */
function getInterpolatedValue(track, time) {
	const bufferType = track.ValueTypeName === 'string' || track.ValueTypeName === 'bool'
		? Array
		: Float64Array;

	const interpolant = track.createInterpolant(null);

	// see THREE.PropertyMixer
	// layout: [ incoming | accu0 | accu1 | orig ]
	const buffer = new bufferType(track.getValueSize() * 4);
	interpolant.resultBuffer = buffer;

	const interpolatedResult = interpolant.evaluate(time);
	const value = interpolatedResult.slice(0, interpolant.valueSize);

	if (track.createInterpolant.isInterpolantFactoryMethodGLTFCubicSpline) {
		// See GLTFCubicSplineInterpolant...
		// Layout of keyframe output values for CUBICSPLINE animations:
		//   [ inTangent_1, splineVertex_1, outTangent_1, inTangent_2, splineVertex_2, ... ]
		// Since we're generating a keyframe value we need
		// to provide the tangents too, but 0,0,0 seems to
		// work ok for now.
		const ret = new value.constructor(9);
		ret.set(value, 3);
		return ret;
	}

	return value;
}

/**
 * Removes keyframes from a KeyframeTrack which are outside
 * the range [startTime, endTime].
 *
 * This differs from KeyframeTrack.trim() only in that we
 * allow an empty track, because we're going to insert any
 * necessary keyframes afterwards.
 */
function trimTrack(track, startTime, endTime) {
	const times = track.times;
	const nKeys = times.length;
	let from = 0;
	let to = nKeys - 1;

	while (from !== nKeys && times[from] < startTime) {
		++from;
	}

	while (to !== -1 && times[to] > endTime) {
		--to;
	}

	++to; // inclusive -> exclusive bound

	if (from !== 0 || to !== nKeys) {
		const stride = track.getValueSize();
		track.times = THREE.AnimationUtils.arraySlice(times, from, to);
		track.values = THREE.AnimationUtils.arraySlice(track.values, from * stride, to * stride);
	}
}

/*
 * Splits an AnimationClip into two AnimationClips at the
 * given time (in seconds).
 *
 * This modifies the passed clip in-place and returns the
 * new clip;
 */
function splitClip(clip, time, name) {
	const newClip = copyClip(clip);
	newClip.name = name;

	const newDuration = clip.duration - time;
	for (let [i, newTrack] of Object.entries(newClip.tracks)) {
		let track = clip.tracks[i];

		// fetch value at split
		const value = getInterpolatedValue(track, time);

		// filter old track to times before split
		trimTrack(track, 0, time);

		// filter new track to times after split
		newTrack.shift(-time);
		trimTrack(newTrack, 0, newDuration);

		// insert keyframes at split

		if (track.times[track.times.length - 1] !== time) {
			track = insertKeyFrame(track, false, time, value);
		}

		if (newTrack.times.length === 0 || newTrack.times[0] !== 0) {
			newTrack = insertKeyFrame(newTrack, true, 0, value);
		}

		if (newTrack.times.length === 1 && newTrack.times[0] !== newDuration) {
			newTrack = insertKeyFrame(newTrack, false, newDuration, value);
		}

		clip.tracks[i] = track;
		newClip.tracks[i] = newTrack;
	}

	// update clip durations from their tracks
	newClip.resetDuration();
	clip.resetDuration();

	return newClip;
}

export { copyClip, splitClip };
